.Toastify__toast-container {
  --toastify-icon-color-success: #fff;
  --toastify-icon-color-error: #fff;
  --toastify-icon-color-warning: #fff;
}

.Toastify__toast--default {
  background: #fff;
  color: #36487b;
}
.Toastify__toast-body {
  align-items: flex-start;
}
.Toastify__toast--success {
  background: #43a047;
  color: #fff;
}
.Toastify__toast--warning {
  background: #ff9800;
  color: #fff;
}
.Toastify__toast--error {
  background: #d3302f;
  color: #fff;
}

.Toastify__close-button {
  color: #fff;
}

.Toastify__close-button--light {
  color: #fff;
  opacity: 0.8;
}
